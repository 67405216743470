@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}

.collection.menu p strong {
  text-transform: uppercase;
  font-size: 14px;
}

blockquote.mt10 {
  margin: 10px 0;
  padding-left: 0.7rem;
  border-left: 5px solid #a9abab;
  background-color: #f7fafc;
}

.collection.menu {
  margin-top: 30px;
}

.collection.menu a {
  color: #333;
}

.collapsible-body.narrow {
  padding: 0.5rem;
}
.materialboxed {
  max-width: 100%;
}

.btn-small.m10r {
  margin-right: 10px;
}

.collection .collection-item.avatar:not(.circle-clipper)>.circle {
  width: 40px;
  height: 40px;
  left: 12px;
}
.collection .collection-item.avatar :not(.circle-clipper)>.circle {
  width: 40px;
  height: 40px;
  left: 8px;
}

.collection .collection-item {
  padding: 10px 10px;
}

.collection .collection-item.avatar {
  padding-left: 60px;
  min-height: 70px;
}

.collection .collection-item.avatar .title {
  font-size: 25px;
}

.collection .collection-item.avatar a i {
  left: 10px;
}

.collection .collection-item.avatar a .title {
  font-size: 20px;
}

.circle {
  border-radius: unset;
}

.collection a.collection-item {
  font-size: 12px;
}

.collection a.collection-item:not(.active):hover {
  background-color: inherit;
}